import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-homeone-process',
    templateUrl: './homeone-process.component.html',
    styleUrls: ['./homeone-process.component.scss']
})
export class HomeoneProcessComponent implements OnInit {

    constructor() { }

    ngOnInit(): void {
    }

    sectionTitle: sectionTitleContent[] = [
        {
            subTitle: "How It's Work",
            title: 'The Software Development Process',
            paragraphText: `In today's technology-driven world, the software development process serves as the foundation for bringing innovative digital solutions to life. It's a journey that involves careful planning, collaborative analysis, creative design, meticulous coding, and thorough testing. Our skilled developers work hand in hand with you, the visionary, to translate your ideas into a tangible product. We bring your software to life, ensuring it meets your unique requirements and functions seamlessly. Once perfected, we handle the deployment and continue to provide support, keeping your software up-to-date and resilient. It's a dynamic, evolving journey that enables your vision to become a digital reality, and we're here every step of the way, ensuring your software is a success from inception to deployment and beyond. At [Your Company Name], we're dedicated to transforming your concepts into powerful software solutions.`
        }
    ]
    singleProcessBox: processBoxContent[] = [
        {
            img: 'assets/img/process/img1.png',
            title: 'Frame the Problem',
            paragraphText: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt.',
            number: '1'
        },
        {
            img: 'assets/img/process/img2.png',
            title: 'Collect the Raw Data',
            paragraphText: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt.',
            number: '2'
        },
        {
            img: 'assets/img/process/img3.png',
            title: 'Process the Data',
            paragraphText: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt.',
            number: '3'
        },
        {
            img: 'assets/img/process/img4.png',
            title: 'Explore the Data',
            paragraphText: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt.',
            number: '4'
        },
        {
            img: 'assets/img/process/img5.png',
            title: 'Perform In-depth Analysis',
            paragraphText: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt.',
            number: '5'
        },
        {
            img: 'assets/img/process/img6.png',
            title: 'Communicate Results',
            paragraphText: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt.',
            number: '6'
        }
    ]

}
class sectionTitleContent {
    subTitle : string;
    title : string;
    paragraphText : string;
}
class processBoxContent {
    img : string;
    title : string;
    paragraphText : string;
    number : string;
}