import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-solutions',
    templateUrl: './solutions.component.html',
    styleUrls: ['./solutions.component.scss']
})
export class SolutionsComponent implements OnInit {

    constructor() { }

    ngOnInit(): void {
    }

    sectionTitle: sectionTitleContent[] = [
        {
            subTitle: 'Our Solutions',
            title: 'We Different From Others Should Choose Us',
            paragraphText: 'Enonomus builds scalable, high performing, best in class custom mobile apps, websites and desktop applications. We are passionate about building softwares that meet both user needs and business goals.'
        }
    ]
    singleSolutionsBox: solutionsBoxContent[] = [
        {
            icon: 'flaticon-rocket',
            title: 'Startup Applications',
            paragraphText: 'Converting an idea to a fully functional content driven software solution. Helping startups from launching a product to branding. Leave all the hustle at us, sit back and enjoy the process of growing together',
            link: 'services-details',
            linkText: 'View Details'
        },
        {
            icon: 'flaticon-laptop',
            title: 'Health Care Solutions',
            paragraphText: 'Offering Hippa compliance health care solutions. Clinical tested real time patient monitoring systems and IOT products. Providing the tech stack to save lives using our artificial intelligence based early symptom detection tool kits.',
            link: 'services-details',
            linkText: 'View Details'
        },
        {
            icon: 'flaticon-money',
            title: 'E-Commerce Platforms',
            paragraphText: 'Scalable, high performing and tech driven ecommerce platforms. Connecting the business and stores with the customers. From Product selection, Order Placement and delivery to the door steps. Our smart navigation systems save time and money',
            link: 'services-details',
            linkText: 'View Details'
        }
    ]

}
class sectionTitleContent {
    subTitle : string;
    title : string;
    paragraphText : string;
}
class solutionsBoxContent {
    icon : string;
    title : string;
    paragraphText : string;
    link : string;
    linkText : string;
}