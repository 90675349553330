import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-homeone-banner',
    templateUrl: './homeone-banner.component.html',
    styleUrls: ['./homeone-banner.component.scss']
})
export class HomeoneBannerComponent implements OnInit {

    constructor() { }

    ngOnInit(): void {
    }

    mainBannerContent: Content[] = [
        {
            title: 'Data Science, Mobile Apps and Web Solutions',
            paragraphText: 'Get professional & reliable research oriented solutions for Data Science, Mobile Apps, Websites and Machine Learning business needs. Enjoy stress free decesion making. Our end products will make a significant impact on your business!.',
            defaultBtnIcon: 'flaticon-structure',
            defaultBtnText: 'About Us',
            defaultBtnLink: 'about-us'
            // videoBtnIcon: 'flaticon-google-play',
            // videoBtnText: 'Watch Video',
            // videoBtnLink: 'https://www.youtube.com/watch?v=Y5KCDWi7h9o'
        }
    ]

}
class Content {
    title : string;
    paragraphText : string;
    defaultBtnIcon : string;
    defaultBtnText: string;
    defaultBtnLink : string;
    // videoBtnIcon : string;
    // videoBtnText: string;
    // videoBtnLink : string;
}